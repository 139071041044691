import React, { useEffect } from 'react'
import '../Projects/projects.css'
import Navbar from '../../Common/Navbar/Navbar'
import Slider from '../../Common/slider/Slider'
import project21 from '../../images/projects/project21.jpg'
import Footer from '../../Common/Footer/Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Home Cinema and Soundproofing Home Theater</title>
        <meta name="description" content="We are the home theater builders specializing in home cinema entertainment and soundproofing home theater" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Home Cinema Theater Builders | Home Theater Construction and Soundproofing" />
        <meta property="og:description" content="" />
        <meta property="og:locale" content="en-ae" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <link rel="canonical" href="https://www.starhomecinema.com/projects" />
        <link rel="alternate" href="https://www.starhomecinema.com/projects" hreflang="en-ae" />
      </Helmet>
      <section id='projects'>
        <div className='container-fluid header'>
          <Navbar />
          <Slider />
        </div>
        <div className='container projects-content'>
          <h1>Our Projects</h1>
          <div className='row mt-4'>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_1.jpg" alt="projects1" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_2.jpg" alt="projects2" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_3.jpg" alt="projects3" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_4.jpg" alt="projects4" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_5.jpg" alt="projects5" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_6.jpg" alt="projects6" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_7.jpg" alt="projects7" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_8.jpg" alt="projects8" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_9.jpg" alt="projects9" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_10.jpg" alt="projects10" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_11.jpg" alt="projects11" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/voxshj_12.jpg" alt="projects12" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project9.jpg" alt="projects13" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project10.jpg" alt="projects14" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project11.jpg" alt="projects15" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project12.jpg" alt="projects16" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project13.jpg" alt="projects17" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project14.jpg" alt="projects18" className='img-fluid' style={{ padding: "5px", width: "100%" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project15.jpg" alt="projects19" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project16.jpg" alt="projects20" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project17.jpg" alt="projects21" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project18.jpg" alt="projects22" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project19.jpg" alt="projects23" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project20.jpg" alt="projects24" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src={project21} alt="projects25" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project22.jpg" alt="projects26" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project23.jpg" alt="projects27" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project24.jpg" alt="projects28" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/private-home-cinema1.jpg" alt="projects29" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/private-home-cinema3.jpg" alt="projects30" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/private-home-cinema4.jpg" alt="projects31" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/private-home-cinema5.jpg" alt="projects32" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/novo-themepark1.jpg" alt="projects33" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/novo-themepark2.jpg" alt="projects34" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/novo-themepark3.jpg" alt="projects35" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/novo-themepark4.jpg" alt="projects36" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project1.jpg" alt="projects37" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project2.jpg" alt="projects38" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project3.jpg" alt="projects39" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project4.jpg" alt="projects40" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project5.jpg" alt="projects41" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project6.jpg" alt="projects42" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project7.jpg" alt="projects43" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/project8.jpg" alt="projects44" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/mglr_ht1.jpg" alt="projects45" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/mglr_ht2.jpg" alt="projects46" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/mglr_ht3.jpg" alt="projects47" className='img-fluid' style={{ padding: "5px" }} />
            </div>
            <div className='col-md-3 col-sm-4 col-4 d-flex'>
              <img src="https://www.starhomecinema.com/images/projects/mglr_ht4.jpg" alt="projects48" className='img-fluid' style={{ padding: "5px" }} />
            </div>
          </div>

          <div className='col-md-12'>
            <h1>Acoustic Home Cinema Sound Proofing Solutions</h1>
            <p>Star <Link to='/' className='a-tag'>Home Cinema Interior Designing</Link> And Construction Solutions provided by the pioneers in UAE , The Moov Group. Modern designing and soundproofing systems that enables you to listen world class audio clear sounds and even low volume,low tone speeches can be heard with good clarity. It is an awesome experience with the Star Home Cinema designing and constructions.</p>
            <p>Acoustic solutions is a part of soundproofing technology. The technology employed by the professionals in controlling the sound in <Link to='/associates' className='a-tag'>Cinema theaters</Link> and home Cinema theaters. Club houses and ohter public places where the visitors feel noise pollution and other sound hazards should employ the sound controlling tmeasures like acoustic fabrics implementation and sound proofing panels,acousticalsurfaces, acoustic panels.</p>
            <p>You may be wonder, that where we can find all the materials and the professionals doing the same <Link to='https://acousticalsurfaces.in/' className='a-tag' target='blank'>soundproofing installations</Link> for us. Visit acousticalsurfaces.ae or moovgroup.com to get a free consultation and advice over the sound issues and how to employ the technology. Moov Group is the pioneer in the industry serving the people for morethan 25 years.</p>
            <p>Moov Group using world class materials in controlling the sound and the <Link to='/contactus' className='a-tag'>quality acoustical products</Link> used by the company comes for a long time. The engineers in the company visit your site and provide suggestions at free of cost and guide you in a proper way to suite budget.</p>
            <p>Manufacturing facility at U.S.A comply with all quality standards and soundproofing efficiency standards. The money you invest in the acoustic soundproofing is worth to lead a perfect lifestyle in regard with the entertainment in your life.</p>
          </div>
        </div>

        <Footer />
      </section>
    </>
  )
}

export default Projects