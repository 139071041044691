import React, { useEffect } from 'react';
import '../Homepage/home.css';
import '../Company/company.css';
import Navbar from '../../Common/Navbar/Navbar';
import Slider from '../../Common/slider/Slider';
import Footer from '../../Common/Footer/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Company() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Home Cinema Installation and Home Theater Construction</title>
        <meta name="description" content="We are the home theater builders specializing in home cinema acoustics and soundproofing for movie theaters at home." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Home Cinema Installation | Home Theater Construction and Soundproofing" />
        <meta property="og:description" content="" />
        <meta property="og:locale" content="en-ae" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <link rel="canonical" href="https://www.starhomecinema.com/company" />
        <link rel="alternate" href="https://www.starhomecinema.com/company" hreflang="en-ae" />
      </Helmet>
      <section id='company'>
        <div className='header'>
          <Navbar />
          <Slider />
        </div>
        <div className='container company-content'>
          <h1>Company Profile</h1>

          <div className='col-md-12 content'>
            <img src="https://www.starhomecinema.com/images/abt.jpg" alt="company" align="left" />
            <p>
              Star Home Cinema, a sub-division of “THE MOOV GROUP” (www.moovgroup.com) partnered with Acoustic Surfaces Inc. USA, is an industry leader in creating dedicated Home Cinemas. Our expertise is, but not limited to, Designing & Installation of Home Theatres. We undertake the turnkey project.
            </p>
            <p>
              To remain at the forefront, we provide a highly professional all-inclusive approach to Acoustics, Soundproofing, <Link to='/associates' className='a-tag'>Home Cinema Technology</Link> & Lighting Design and also honour the quality delivered to all our respected clients. In essence, we know what it takes to be the best.
            </p>
            <p>
              The overall home theatre design and the system components layout are crucial for the best Home Theater performance and your personal comfort while enjoying a movie at home. If a dedicated, high-end home theatre for watching movies and listening to your favourite music is your dream, Star Home Cinema offers the complete services that help to realize your dream.
            </p>
            <p>
              Star Home Cinema expertise in perfect Acoustic <Link to='/howwework' className='a-tag'>Home Theater Design</Link> & Installation , Led Lighting control systems, Home Automation Control systems, Projector & Screen display installations, Sound Control Systems, Speaker installations, Home theatre Seating analysis & arrangements, Soundproofing solutions which comprises the complete the home cinema experience. As our expertise encompasses all the elements of the room - Sound, Projection, Lighting, Automation, HVAC, Acoustics & Seating, we provide the detailed specifications and requirements for all the components for the project.
            </p>
            <p>
              A customized Home Theater project usually begins with the project requirement based on the area concerned regarding the room or that has been designated for the Home Theater and the availability regarding the options specified to room's size. The next phase initiates with our Home Theater design, drawings and requirement analysis. Further to this we provide our complete range of Home Theater products & packages from which the concerned can be chosen based on budget requirements.
            </p>
            <p>
              Initially we process with a preliminary design for the <Link to='/distributors' className='a-tag'>Home Cinema Theater</Link> and once it has been completed, Star Home Cinema will present you with the design specifications, including equipment’s and Audio Visual systems for your home theater. We will also detail information on the pricing structure for the equipment’s, its installation and configuration or customization required to tailor the equipment to your home theater space. Star Home Cinema assists you on the complete sectors that include Electrical, HVAC, Carpentry, Interior design, Lighting design, Sound Systems, Projector display to ensure all equipment, cabling and devices are installed to specification and gives the best performance for your Home Theater.
            </p>
            <p>
              Once the <Link to='/contactus' className='a-tag'>Home Theater installation</Link> is complete, the Quality Control Team of Star Home Cinema will test all the equipment’s which has been installed to calibrate the equipment for optimal sound and picture quality and also ensures that it is functioning properly. Finally, we will educate and train you on the proper usage of the home cinema system.
            </p>
          </div>
        </div>
        <Footer />
      </section>
    </>
  )
}

export default Company