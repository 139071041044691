import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Homepage/Home';
import Company from './Components/Company/Company';
import Howwework from './Components/How-we-work/Howwework';
import Expertise from './Components/Expertise/Expertise';
import Projects from './Components/Projects/Projects';
import Associates from './Components/Associates/Associates';
import Distributors from './Components/Distributors/Distributors';
import Faq from './Components/FAQ/Faq';
import Contactus from './Components/Contactus/Contactus';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/howwework" element={<Howwework />} />
          <Route exact path="/expertise" element={<Expertise />} />
          <Route exact path="/associates" element={<Associates />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/distributors" element={<Distributors />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/contactus" element={<Contactus />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
