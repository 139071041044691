import React, { useEffect } from 'react'
import '../FAQ/faq.css'
import Navbar from '../../Common/Navbar/Navbar'
import Slider from '../../Common/slider/Slider'
import Footer from '../../Common/Footer/Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Home Theater Construction and Soundproofing Home Theater</title>
        <meta name="description" content="We are the home theater builders specializing in home theater construction and soundproofing home theater" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Cinema Soundproofing | Home Theater Construction and Soundproofing" />
        <meta property="og:description" content="" />
        <meta property="og:locale" content="en-ae" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <link rel="canonical" href="https://www.starhomecinema.com/faq" />
        <link rel="alternate" href="https://www.starhomecinema.com/faq" hreflang="en-ae" />
      </Helmet>
      <section id='faq'>
        <div className='container-fluid header'>
          <Navbar />
          <Slider />
        </div>
        <div className='container faq-content'>
          <h1>Frequently Asked Questions</h1>
          <div className='col-md-12'>
            <p>Do you have a question about <Link to='https://acousticalsurfaces.in/' className='a-tag' target='blank'>soundproofing</Link> your home theater? Star <Link to='/' className='a-tag'>Home Cinema</Link> has the frequently asked questions for sound proofing concern. Please have a glance at the list of queries below. Kindly ensure these are for soundproofing related queries.</p>
            <h3>1. What is Soundproofing?</h3>
            <p>The goal of soundproofing is the reduction of sound leakage into or out of a room.</p>
            <div className='borders-bottom'></div>
          </div>

          <div className='col-md-12'>
            <h3>2. Do I need soundproofing? I don't have close neighbors and everybody in my home watches movies at the same time!</h3>
            <p>Soundproofing refers to sound leakage both going out of the room (sound generated by your theater) and the sound entering the room. Arguably, controlling the sound entering a room might be more important than controlling it from leaving.</p>
            <div className='borders-bottom'></div>
          </div>

          <div className='col-md-12'>
            <h3>3. Why? Who cares what happens outside the theater?</h3>
            <p>Sound leaking into the conditioned space of your theater adds to the noise floor in the room. This requires that in order to hear the quietest parts of a movie, you'd need to turn up the volume quite a bit just to hear over the noise from outside the room. How much? This quote by Dennis Erskine says it all:</p>
            <p>"The softest sound on a sound track is 22dB. The typical <Link to='/expertise' className='a-tag'>noise</Link> floor in a quiet home in a quiet area is 33dB to 35dB. That is in the range of 6 to 7 times louder than the bottom of the sound track. Ok, so for whispers, and other low dB cues and voices on the sound track, that's no big deal...turn up the volume. But, now you run into problems. Normal speech is now 6-7 times louder ... talking is now yelling on thesound track. Next comes the louder sounds ... traffic, bombs, gun fire, etc. They are now 6-7 times louder as well ... too loud for comfort (or realistic listening). With the top of the dynamic range in asound track at 105dB, that now must be 6-8 times louder. The problem is the vast majority of consumer equipment (amps/speakers) cannot handle that increase on the top end ... blowing out tweeters, clipping the amps, or amplifier distortion as you reach those levels."</p>
            <div className='borders-bottom'></div>
          </div>

          <div className='col-md-12'>
            <h3>4. What's the difference between soundproofing and acoustics?</h3>
            <p>Technically, soundproofing is a form of acoustic control, but most people think of them as two different categories. Soundproofing is all about controlling the sound leakage in and out of the room. Acoustics is all about controlling the sound that is in the room. Think echos, reflections, reverberations, and the like. Both are important, if not at the same level. Soundproofing is likely important to many home theater builders -- acoustics is important to all of them.</p>
            <div className='borders-bottom'></div>
          </div>

          <div className='col-md-12'>
            <h3>5. How do I apply those elements, practically?</h3>
            <p>There are many ways to soundproof a room, and those elements can be adapted to fit many types of situations. Here are some high level thoughts:</p>
            <p>First, address your walls. If you are able, consider decoupling them by creating a "room within a room". This is a separate 2x4 wall separated from your existing walls by an inch or so. If space is tight and it is new construction, then consider a staggered stud wall. Another alternative is to float the drywall by attaching the sheets to channels that are separated from the studs via clips. Add mass by layering at least two layers of 5/8" Type X drywall. Damp the drywall by putting Green Glue (or equivalent) between the sheets. Add absorption with insulation.</p>
            <p>Then, do the same with the ceiling. Your ceiling is likely connected to either the floor above you or to the shared attic with the rest of the house. Decouple it by "floating" the ceiling, either using floating joists (in a "room within a room" concept) or via clips and channels. The former costs less from a materials perspective, but requires more room and is not always feasible. Add mass, damp, and add sound absorption the exact same way as the walls.</p>
            <p>Now take care of your door. The door is typically the weakest link in the soundproofing chain, since it will be substantially thinner than the walls and will also need to be moveable. At the very least, get a 1 3/4" solid core door. Then add perimeter door seals (like weather-stripping). Consider an automatic door bottom to seal off the bottom of the door. If you need more interior soundproofing, then try adding more mass to the door using layers of MDF and damp with Green Glue. If even that is not enough, then (assuming you have room), create an "airlock" with two sealed solid core doors. That's the decoupling component.</p>
            <p>You might also look at your floor, especially if it is on a concrete slab. Concrete transmits low frequencies very well and so it's possible for vibrations from air conditioning units or freezers or the like to intrude in your theater. Combat this by decoupling the floor via an additional layer of OSB or plywood laying on an underlayment. The underlayment adds mass and some sound absorption.</p>
          </div>
        </div>
        <Footer />
      </section>
    </>
  )
}

export default Faq