import React, { useEffect } from 'react';


function Slider() {

    useEffect(() => {
        const carouselInterval = setInterval(() => {
            const carouselElement = document.getElementById('carouselExampleIndicators');
            new window.bootstrap.Carousel(carouselElement, {
                interval: 2000
            });
        }, 2000);

        return () => clearInterval(carouselInterval);
    }, []);

    const carouselText = (
        <div className="carousel-text">
            <h2>BRING THE THEATER <br />
                EXPERIENCE TO YOUR HOME</h2>
            <p>AN UNBEATABLE COMBINATION OF HOME THEATER & ACOUSTICS</p>
        </div>
    );

    return (
        <div className='container-fluid header'>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {[0, 1, 2].map((index) => (
                        <button key={index} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-label={`Slide ${index + 1}`}></button>))}
                </div>
                <div className="carousel-inner">
                    {[3, 2, 1].map((imageIndex, index) => (
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                            <img src={`https://www.starhomecinema.com/images/banner${imageIndex}.webp`} className="d-block w-100" alt={`Slide ${imageIndex}`} />
                        </div>
                    ))}
                    {carouselText}
                </div>
            </div>
        </div>
    )
}

export default Slider