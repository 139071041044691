import React, { useEffect, useState } from 'react'
import '../Contactus/contactus.css'
import Navbar from '../../Common/Navbar/Navbar'
import Footer from '../../Common/Footer/Footer'
import { Helmet } from 'react-helmet';

function Contactus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    let errorMessage = '';

    // Validation logic for each field
    switch (id) {
      case 'firstName':
      case 'lastName':
        if (!/^[A-Za-z\s]*$/.test(value)) {
          errorMessage = `Enter characters only in ${id === 'name' ? 'First Name' : 'Last Name'} field`;
        } else if (value.trim().length < 3) {
          errorMessage = `Enter at least 3 characters in ${id === 'name' ? 'First Name' : 'Last Name'} field`;
        }
        break;
      case 'email':
        if (value.trim() !== '' && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
          errorMessage = 'Email address is not valid';
        }
        break;
      case 'phone':
        if (!/^(\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)) {
          errorMessage = 'Enter a valid 10-digit phone number';
        }
        break;
      default:
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };




  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if there are any errors in the form data
    const formIsValid = Object.values(errors).every((error) => error === '');

    // Check if any required fields are empty
    const requiredFields = ['firstName', 'lastName', 'email', 'phone', 'message'];
    const emptyFieldErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        emptyFieldErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (formIsValid && Object.keys(emptyFieldErrors).length === 0) {
      // Construct the mailto link
      const { firstName, lastName, email, phone, message } = formData;
      const subject = encodeURIComponent('Contact Form Submission');
      const body = encodeURIComponent(`Name: ${firstName} ${lastName}\nEmail: ${email}\nPhone: ${phone}\nMessage: ${message}`);
      const mailtoLink = `mailto:info@starhomecinema.com?subject=${subject}&body=${body}`;

      // Open the user's email client with the pre-filled email
      window.location.href = mailtoLink;
      // Reset the form data
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      });

      // Reset the errors
      setErrors({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      });

    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...emptyFieldErrors,
      }));
    }
  };


  return (
    <>
      <Helmet>
        <title>Home Theater Designs and Setup</title>
        <meta name="description" content="We are the home theater builders specializing in home theater designs and setup" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Home Theater Builders | Home Theater Construction and Soundproofing" />
        <meta property="og:description" content="" />
        <meta property="og:locale" content="en-ae" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <link rel="canonical" href="https://www.starhomecinema.com/contact-us" />
        <link rel="alternate" href="https://www.starhomecinema.com/contact-us" hreflang="en-ae" />
      </Helmet>
      <section id='contactus'>
        <Navbar />
        <div className='container contactus-content'>
          <div className='row'>
            <div className='col-md-6 p-0'>
              <h1>Contact Us</h1>
              <strong>INDIA</strong><br />
              <b>Acoustical Surfaces Industries</b><br />
              <p>130/2B, Morappampalayam, Uppilipalayam (P.O)</p>
              <p>Karuvalur, Tirupur - 641670, India</p>
              <p>Tel : +91 80 257 22022, Mob : +91 8277331000</p>
              <p>Email: info@starhomecinema.com</p><br />

              <strong>UAE</strong><br />
              <b>Moov Group</b><br />
              <p>Office No# B2002, Latifa Tower</p>
              <p>Sheikh Zayed Road, P.O.Box: 113239, Dubai - UAE.</p>
              <p>Tel: +971 4 331 0000</p>
              <p>Email: info@starhomecinema.com</p><br />

              <strong>USA</strong><br />
              <b>Acoustical Surfaces, Inc.</b><br />
              <p>123 Columbia Court North,</p>
              <p>Suite 201 Chaska, MN 55318-USA</p>
              <p>Tel : (952)-448-5300 Fax : (952)-448-2613 1-(800)-854-2948</p>
              <p>Email: info@starhomecinema.com</p>
            </div>

            <div className='col-md-6 p-5'>
              <h4>Enquiry</h4>
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-group">
                  <input
                    placeholder="First Name"
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <span className="error-message">{errors.firstName}</span>
                </div>
                <br />
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />                <span className="error-message">{errors.lastName}</span>
                </div>
                <br />
                <div className="form-group">
                  <input
                    placeholder="Email-id"
                    type="email"
                    className="form-control"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                  />                <span className="error-message">{errors.email}</span>
                </div>
                <br />
                <div className="form-group">
                  <input
                    type="phone"
                    className="form-control"
                    id="phone"
                    placeholder="Your mobile"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <span className="error-message">{errors.phone}</span>
                </div>
                <br />
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="message"
                    rows="3"
                    placeholder="Enter your message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  <span className="error-message">{errors.message}</span>
                </div>
                <br />
                <button type="submit" className="form-btn mt-3">Send Message</button>
              </form>
            </div>
          </div>
          <div className='col-sm-12 mt-5'>
            <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d925134.3210715873!2d54.568041327437584!3d25.0745656650172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1713779301294!5m2!1sen!2sin" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

        <Footer />
      </section>
    </>
  )
}

export default Contactus