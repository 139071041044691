import React, { useState, useEffect } from 'react'
import '../Distributors/distributors.css'
import Navbar from '../../Common/Navbar/Navbar'
import Slider from '../../Common/slider/Slider'
import Footer from '../../Common/Footer/Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Distributors() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    let errorMessage = '';

    // Validation logic for each field
    switch (id) {
      case 'name':
      case 'companyName':
        if (!/^[A-Za-z\s]*$/.test(value)) {
          errorMessage = `Enter characters only in ${id === 'name' ? 'Name' : 'Company Name'} field`;
        } else if (value.trim().length < 3) {
          errorMessage = `Enter at least 3 characters in ${id === 'name' ? 'Name' : 'Company Name'} field`;
        }
        break;
      case 'email':
        if (value.trim() !== '' && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
          errorMessage = 'Email address is not valid';
        }
        break;
      case 'phone':
        if (!/^(\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)) {
          errorMessage = 'Enter a valid 10-digit phone number';
        }
        break;
      default:
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };




  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if there are any errors in the form data
    const formIsValid = Object.values(errors).every((error) => error === '');

    // Check if any required fields are empty
    const requiredFields = ['name', 'companyName', 'email', 'phone', 'message'];
    const emptyFieldErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field].trim()) {
        emptyFieldErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (formIsValid && Object.keys(emptyFieldErrors).length === 0) {
      // Construct the mailto link
      const { name, companyName, email, phone, message } = formData;
      const subject = encodeURIComponent('Contact Form Submission');
      const body = encodeURIComponent(`Name: ${name}\nCompany Name: ${companyName}\nEmail: ${email}\nPhone: ${phone}\nMessage: ${message}`);
      const mailtoLink = `mailto:info@starhomecinema.com?subject=${subject}&body=${body}`;

      // Open the user's email client with the pre-filled email
      window.location.href = mailtoLink;
      // Reset the form data
      setFormData({
        name: '',
        companyName: '',
        email: '',
        phone: '',
        message: '',
      });

      // Reset the errors
      setErrors({
        name: '',
        companyName: '',
        email: '',
        phone: '',
        message: '',
      });

    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...emptyFieldErrors,
      }));
    }
  };


  return (
    <>
      <Helmet>
        <title>Home Cinema Room Construction and Soundproofing Acoustics</title>
        <meta name="description" content="We are the home theater builders specializing in home cinema room construction and soundproofing acoustics" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Home Cinema Room Construction | Home Theater Construction and Soundproofing" />
        <meta property="og:description" content="" />
        <meta property="og:locale" content="en-ae" />
        <meta name="geo.placename" content="Dubai" />
        <meta http-equiv="content-language" content="ae" />
        <meta name="geo.region" content="Dubai" />
        <link rel="canonical" href="https://www.starhomecinema.com/distributor" />
        <link rel="alternate" href="https://www.starhomecinema.com/distributor" hreflang="en-ae" />
      </Helmet>
      <section id='distributor'>
        <div className='container-fluid header'>
          <Navbar />
          <Slider />
        </div>
        <div className='container distributor-content'>
          <div className='row'>
            <div className='col-md-6'>
              <h1>Become a Distributor</h1>
              <p>If you are looking forward to become a distributor for <Link to='/' className='a-tag'>Home Theaters</Link> or a referral partner and have any queries regarding the requirements and procedures please contact us to initiate the discussion. It would help if you describe what kind of a business plan you have in mind.</p>
              <img src="https://www.starhomecinema.com/images/distrubuter.jpg" alt="distributor" className='img-fluid' />
            </div>

            <div className='col-md-6 mt-5 p-4'>
              <h4>Request Form</h4>
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-group">
                  <input
                    placeholder="Name"
                    type="text"
                    className="form-control"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <span className="error-message">{errors.name}</span>
                </div>
                <br />
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Company Name"
                    className="form-control"
                    id="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                  />                <span className="error-message">{errors.companyName}</span>
                </div>
                <br />
                <div className="form-group">
                  <input
                    placeholder="Email-id"
                    type="email"
                    className="form-control"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                  />                <span className="error-message">{errors.email}</span>
                </div>
                <br />
                <div className="form-group">
                  <input
                    type="phone"
                    className="form-control"
                    id="phone"
                    placeholder="Your mobile"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <span className="error-message">{errors.phone}</span>
                </div>
                <br />
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="message"
                    rows="3"
                    placeholder="Enter your message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  <span className="error-message">{errors.message}</span>
                </div>
                <br />
                <button type="submit" className="form-btn mt-3">Send Message</button>
              </form>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </>
  )
}

export default Distributors