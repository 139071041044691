import React, { useState } from 'react';
import '../../Components/Homepage/Home';
import fb from '../../images/facebook.png';
import linkedin from '../../images/linkedin.png';
import twitter from '../../images/twitter.png';
import youtube from '../../images/youtube.png';
import { Link } from 'react-router-dom';
// import boucher from '../../images/ebochure.png';

function Navbar() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <div className='container-fluid header'>
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-6 d-flex justify-content-center align-items-center logo'>
                    <img src="../../images/logo.png" alt="logo" />
                </div>

                <div className='col-12 col-sm-12 col-md-6 phn-logo'>
                    <ul className='social-icons m-0'>
                        <Link to="https://www.facebook.com/starhomecinema?ref=hl" target='blank'><img src={fb} alt="fb" /></Link>
                        <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FStarHomeCinema" target='blank'><img src={twitter} alt="twitter" /></Link>
                        <Link to="https://www.linkedin.com/company/star-home-cinema?trk=top_nav_home" target='blank'><img src={linkedin} alt="linkedin" /></Link>
                        <Link to="https://www.youtube.com/channel/UCF03GVsLTqbO0Cauchzfb4w" target='blank'><img src={youtube} alt="youtube" /></Link>
                    </ul>
                    <div className="col flag d-flex align-items-center">
                        <img src="./images/uae.png" alt="phn" />
                        <p className='ml-2 mb-1'>+971 4331 0000</p>
                    </div>

                    <div className="col flag d-flex align-items-center">
                        <img src="./images/india.png" alt="phn" />
                        <p className='ml-2 mb-1'>+91 8277331000</p>
                    </div>

                </div>
            </div>

            <div className='row'>
                <div className='col-md-5 nav-smg d-flex justify-content-end p-0'>
                    <img src="../images/nav-right.png" alt="navbar-pic" />
                </div>
                <div className='col-lg-7 col-md-12 col-sm-12 col-12 nav-list'>
                    <ul className='nav-ul'>
                        <Link to="/"><li>Home</li></Link>
                        <Link to="/company"><li>Company</li></Link>
                        <Link to="/howwework"><li>How We Work</li></Link>
                        <Link to="/expertise"><li>Expertise</li></Link>
                        <Link to="/associates"><li>Associates</li></Link>
                        <Link to="/projects"><li>Projects</li></Link>
                        <Link to="/distributors"><li>Distributor</li></Link>
                        <Link to="/faq"><li>FAQ</li></Link>
                        <Link to="/contactus"><li>Contact Us</li></Link>
                    </ul>
                </div>
                <button className={`navbar-toggler ml-auto  ${isNavOpen ? 'active' : ''}`} type="button" onClick={toggleNav} data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">&#9776;</span>
                    {isNavOpen && (
                        <ul className='nav-ul'>
                            <Link to="/"><li>Home</li></Link>
                            <Link to="/company"><li>Company</li></Link>
                            <Link to="/howwework"><li>How We Work</li></Link>
                            <Link to="/expertise"><li>Expertise</li></Link>
                            <Link to="/associates"><li>Associates</li></Link>
                            <Link to="/projects"><li>Projects</li></Link>
                            <Link to="/distributors"><li>Distributor</li></Link>
                            <Link to="/faq"><li>FAQ</li></Link>
                            <Link to="/contactus"><li>Contact Us</li></Link>
                        </ul>
                    )}
                </button>
            </div>
            {/* <div className='fixed-image d-flex justify-content-end'>
                <img src={boucher} alt="sticky" />
            </div> */}
        </div>
    )
}


export default Navbar;