import React, { useEffect } from 'react'
import Slider from '../../Common/slider/Slider'
import '../Expertise/expertise.css'
import Navbar from '../../Common/Navbar/Navbar'
import Footer from '../../Common/Footer/Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Expertise() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Custom Home Cinema Installation and Theater at Home | Home Cinema Acoustics in Dubai</title>
                <meta name="description" content="We are the home theater builders specializing in custome home cinema installation and theater at home" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Home Cinema Sound System Construction | Home Theater Construction and Soundproofing" />
                <meta property="og:description" content="" />
                <meta property="og:locale" content="en-ae" />
                <meta name="geo.placename" content="Dubai" />
                <meta http-equiv="content-language" content="ae" />
                <meta name="geo.region" content="Dubai" />
                <link rel="canonical" href="https://www.starhomecinema.com/expertise" />
                <link rel="alternate" href="https://www.starhomecinema.com/expertise" hreflang="en-ae" />
            </Helmet>
            <section id='expertise'>
                <div className='header'>
                    <Navbar />
                    <Slider />
                </div>
                <div className='container expertise-content'>
                    <h1>Area of Expertise</h1>
                    <div className='row mt-4'>
                        <div className='col-md-4 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/acoustic-sound-control-systems.jpg" alt="expertise1" className='img-fluid' />
                        </div>
                        <div className='col-md-8 col-sm-12'>
                            <h2><Link to="/" className='head-tag'>Acoustic & Sound Control</Link> Systems</h2>
                            <p>Every theater space has unique <Link to='/company' className='a-tag'>acoustical</Link> challenges and every client has his or her own personal style. Our solution is engineered to control all aspects of your room's acoustics so your theater sounds outstanding no matter what you're watching while reflecting your tastes.</p>
                            <p>Unlike many home theater acoustics companies, our acoustical panels are produced in our own manufacturing facility. Whether you need standard-sized or fabric-wrapped acoustic panels you can be assured of receiving the highest quality at competitive prices.</p>
                            <p>We economically enhance a room's acoustical home cinema performance and visual beauty with strategically-placed acoustic wall panels and many other products which you can choose from over 400 different acoustical products in standard or custom panel designs.</p>
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <h2>Sound Proofing</h2>
                            <p>We guide you throughout the entire process starting from consultation to installation of designing your <Link to='/associates' className='a-tag'>Home cinema Theater</Link>. It seems there are plenty of approaches to soundproofing that don’t work or don’t work well, so what does it take to successfully soundproof a home theater?</p>
                            <p>The science of <Link to='https://acousticalsurfaces.in/' className='a-tag' target='blank'>soundproofing</Link> boils down to three things: mass, damping, and decoupling. You can achieve varying degrees of soundproofing by adding mass, damping wall surfaces so they don’t vibrate, and most important, decoupling structures so they aren’t physically connected. We provide you a combination of all the three. The right mass, correctly damped with sufficient decoupling, will give you very good separation between spaces.” And isolation is what you want when it comes to sound systems that pump out enough bass to send shockwaves throughout the house.</p>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/sound-proofing.jpg" alt="expertise2" className='img-fluid' />
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-4 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/aesthetic-interiors.jpg" alt="expertise3" className='img-fluid' />
                        </div>
                        <div className='col-md-8 col-sm-12'>
                            <h2>Aesthetic Interiors</h2>
                            <p>The moment you contact Star <Link to='/' className='a-tag'>Home Cinema</Link>, we will guide you throughout the entire process of designing your Home Theater. An effective home theater showcases your individuality.</p>
                            <p>In terms of design, your Home cinema Theater is the most versatile room in your home. We partner with you, earn your trust and create your vision. Our design team works with you to understand exactly your idea and expectation.</p>
                            <p>If you prefer a theme oriented <Link to='/distributors' className='a-tag'>Home cinema Theater Design</Link>, even then we can design as per your requirements. Either way, will patiently work with you until you are 100% satisfied with your final design concept.</p>
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <h2>Seating</h2>
                            <p>Home cinema theater is a place for relaxation and enjoyment and you will spend several hours immersed in the world of the movies. If your seat is uncomfortable no matter how great the flick, your level of enjoyment may suffer.</p>
                            <p>We offer pioneered comfortable reclining theater seating that gives a complete package of relaxation and enjoyment. We provide quality seating and have different models for selection.</p>
                            <p>We have continued to develop designs to compliment architectural styles and current design trends. With more offerings for customization, you'll be sure to find the perfect fit for your lifestyle!</p>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/seating.jpg" alt="expertise4" className='img-fluid' />
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-4 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/automation.jpg" alt="expertise5" className='img-fluid' />
                        </div>
                        <div className='col-md-8 col-sm-12'>
                            <h2>Automation</h2>
                            <p>Why stop at home cinema or lighting control? Blinds, access control, gate, garage door, heating, air conditioning all this can be fully integrated with today’s control systems.</p>
                            <p>From a localized control system with a universal remote control to the top of the range integrated system with wall and mobile touch screens, we can design, program and install the best suitable solutions. The internet and 3G technologies have made true remote access and control a reality. Imagine that you are away on holidays and have just landed at the airport; you have set your heating back to save energy while away. A quick touch of a few keys on your phone and you can set the heating back to home mode; and by the time you arrive the house is warm and the lights are on to greet you. Monitor cameras from any computer or 3G smart phone. All this is possible with today’s systems and we have wide range of product selection that we regularly install.</p>
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <h2>Screen & Projector</h2>
                            <p>Basically cinema Screen size is determined upon the room’s dimension. Hence we always recommend our clients the perfect screen to suit their requirements, the aesthetics of their environments and their budget. The screen size is very important to your overall theater experience.</p>
                            <p>We work with all the main manufacturers, and as such we do not limit ourselves to specific brands or models. With High Definition (HD) widespread now and many broadcasters launching HD channels, we are in constant contact with the manufacturers which make easy to serve and guide our clients.</p>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/screen-projector.jpg" alt="expertise6" className='img-fluid' />
                        </div>
                        <div className='borders-bottom'></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-4 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/lighting.jpg" alt="expertise7" className='img-fluid' />
                        </div>
                        <div className='col-md-8 col-sm-12'>
                            <h2>Lighting</h2>
                            <p>Beautiful sounding home <Link to='/distributors' className='a-tag'>cinema theaters</Link> are exciting when the lights are perfectly synergized with your home theater design.</p>
                            <p>Good lighting brings a house to life, It provides costs savings and adds security to a property. From one-room solution up to whole house packages, clients can control the numerous circuits of a house with simple scene settings via a remote control, an iPhone/iPad, a wall touch screen or keypad. We can integrate this into whole house AV controllers as well. With a large selection of lighting control systems and brands, we have a solution for all budgets or projects.</p>
                        </div>
                    </div>
                </div>

                <Footer />
            </section>
        </>
    )
}

export default Expertise