import React, { useEffect } from 'react'
import '../Associates/associates.css'
import Navbar from '../../Common/Navbar/Navbar'
import Slider from '../../Common/slider/Slider'
import Footer from '../../Common/Footer/Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Associates() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Home Theater Soundproofing Panels and Home Cinema Room Construction at Acoustics</title>
                <meta name="description" content="We are the home theater builders specializing in home cinema acoustics and home cinema sound system construction" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Home Theater Designs and Setup | Home Theater Construction and Soundproofing" />
                <meta property="og:description" content="" />
                <meta property="og:locale" content="en-ae" />
                <meta name="geo.placename" content="Dubai" />
                <meta http-equiv="content-language" content="ae" />
                <meta name="geo.region" content="Dubai" />
                <link rel="canonical" href="https://www.starhomecinema.com/associates" />
                <link rel="alternate" href="https://www.starhomecinema.com/associates" hreflang="en-ae" />
            </Helmet>
            <section id='associates'>
                <div className='header'>
                    <Navbar />
                    <Slider />
                </div>
                <div className='container associates-content'>
                    <h1>Associates</h1>
                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12 stopnoise'>
                            <img src="https://www.starhomecinema.com/images/brand1.png" alt="stopnoise" className='img-fluid brand-image pr-3' align="left" />
                            <div className='associates1'>
                                <h3>ACOUSTICAL SURFACES INC., USA</h3>
                                <h4>Acoustical & Soundproofing Products</h4>
                                <p>Acoustical Surfaces Inc. has been solving soundproofing, noise control, acoustical and vibration problems for over 35 years. With over 400 specialty soundproofing and noise control products and materials, ASI has a solution for almost any noise problem. We are a member company of Acoustical Surfaces Inc and use their acoustical products for your requirement.</p>
                            </div>
                            <button className='btn'>Read More</button>
                        </div>
                        <div className='col-md-4 col-sm-12 mt-2'>
                            <img src="https://www.starhomecinema.com/images/brands-acoustical-surfaces.jpg" alt="associates" className='img-fluid' />
                        </div>
                        <div className='border-bottom' style={{ marginTop: "10px", height: "20px" }}></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/brand2.png" alt="stopnoise1" className='img-fluid brand-image' align="left" />
                            <div className='associates1'>
                                <h3>KEITH YATES DESIGN GROUP, USA</h3>
                                <h4>Home Cinema Theater Design Consultancy</h4>
                                <p>Keith Yates Design Group designs acoustically optimized Home Theaters, Media Rooms and Precision Listening Environments. Our goal is not simply to place you in a movie theater ... it's to place you in the movie. We call it "Performance-Based <Link to='/' className='a-tag'>cinema Room Design(SM)</Link>" and it enables music and film lovers to improve, dramatically, the immersive power of the entertainment experience. We are associated with Keith Yates for design support of high end Home Cinemas.</p>
                            </div>
                            <button className='btn'>Read More</button>
                        </div>
                        <div className='col-md-4 col-sm-12 mt-2'>
                            <img src="https://www.starhomecinema.com/images/brands-img6.jpg" alt="associates2" className='img-fluid' />
                        </div>
                        <div className='border-bottom' style={{ marginTop: "10px", height: "20px" }}></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/brand3.png" alt="stopnoise" className='img-fluid brand-image' align="left" />
                            <div className='associates1'>
                                <h3>HOMETECH EUROPE, BRITON</h3>
                                <h4>AV Automation</h4>
                                <p>HOMETECH EUROPE, team pioneered the design and installation of home automation systems on the West coast of the US. With over 25 years of experience, we remain at the forefront of the industry providing a highly professional all-inclusive approach to home technology and lighting design, from design to supply, installation and maintenance for private clients, architects and interior designers mainly located in the US and in Europe. We are in tie-up with HOMETECH EUROPE for the design and installation of AV system.</p>
                            </div>
                            <button className='btn'>Read More</button>
                        </div>
                        <div className='col-md-4 col-sm-12 mt-2'>
                            <img src="https://www.starhomecinema.com/images/brands-img2.jpg" alt="associates3" className='img-fluid' />
                        </div>
                        <div className='border-bottom' style={{ marginTop: "10px", height: "20px" }}></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/brand4.png" alt="stopnoise" className='img-fluid brand-image' align="left" />
                            <div className='associates1'>
                                <h3>CINEAK, BELGIUM</h3>
                                <h4>Home Theater Seating</h4>
                                <p>CINEAK company was founded in 1999 as a response to the mass-produced, unattractive, uncomfortable and limited home theatre / media room seating options available. Since its inception, CINEAK has revolutionized the luxury home cinema seating experience through its passion for excellence in materials, construction, technology, quality, service and attention to detail. Each of our chairs are carefully designed and hand-crafted in Belgium with the finest materials available. We are extremely proud of our collection of unique products and hope to help you create the ultimate entertainment experience. We represent and propose CINEAK seats to you.</p>
                            </div>
                            <button className='btn'>Read More</button>
                        </div>
                        <div className='col-md-4 col-sm-12 mt-2'>
                            <img src="https://www.starhomecinema.com/images/brands-img3.jpg" alt="associates4" className='img-fluid' />
                        </div>
                        <div className='border-bottom' style={{ marginTop: "10px", height: "20px" }}></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/brand5.png" alt="stopnoise" className='img-fluid brand-image' align="left" />
                            <div className='associates1'>
                                <h3>BOSE CORPORATION</h3>
                                <h4>Speaker</h4>
                                <p>Bose home audio solutions include Lifestyle速 <Link to='/distributors' className='a-tag'>home theater surround sound</Link> systems, Wave速 products, stereo and surround sound speakers, computer speakers, outdoor and marine speakers, and custom home cinema installations. We propose and supply BOSE speaker system during construction of Home Cinema.</p>
                            </div>
                            <button className='btn'>Read More</button>
                        </div>
                        <div className='col-md-4 col-sm-12 mt-2'>
                            <img src="https://www.starhomecinema.com/images/brands-img4.jpg" alt="associates5" className='img-fluid' />
                        </div>
                        <div className='border-bottom' style={{ marginTop: "10px", height: "20px" }}></div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-8 col-sm-12'>
                            <img src="https://www.starhomecinema.com/images/brand-moov.png" alt="stopnoise" className='img-fluid brand-image' align="left" />
                            <div className='associates1'>
                                <h3>MOOV INTERIOR</h3>
                                <h4>Interior Fitout</h4>
                                <p>We offer turnkey solutions in the field of <Link to='/company' className='a-tag'>interior design & fit out</Link>s. We are specialized in the field of joinery and interiors for hotels, restaurants, malls, offices, showrooms, commercial buildings, villas etc. Our designs are aimed at creating pleasant and captivating environments whether for commercial or residential projects. High-quality materials imported from all over the world are used in the joinery production We continue to deliver aesthetic and economic solutions for optimum space utilization and productivity, and specialize in state of the art fit-outs. Our combination of science, art and technology does not only make our interiors aesthetically unique and functional, but also economical and of excellent quality. All the interior joinery works are done by Interior division of MOOV GROUP.</p>
                            </div>
                            <button className='btn'>Read More</button>
                        </div>
                        <div className='col-md-4 col-sm-12 mt-2'>
                            <img src="https://www.starhomecinema.com/images/brands-img5.jpg" alt="associates6" className='img-fluid' />
                        </div>
                        <div className='border-bottom' style={{ marginTop: "10px", height: "20px" }}></div>
                    </div>
                </div>

                <Footer />
            </section>
        </>
    )
}

export default Associates